<template>
  <div class="view-wrapper custom-card-tree-view">
    <!--      <v-row no-gutters class="top-row">-->
    <!--        <v-col cols="8" class="overflow-y-auto fill-parent-height">-->
    <v-card>
      <v-card-title class="pa-1">
        <v-tabs
            ref="tabs"
            v-model="tab"
            style="position:sticky; top: 0; z-index: 1"
            class="mt-0 pt-0"
            show-arrows
            color="success"
        >
          <v-tab
              v-for="item in types"
              :key="item.index"
          >
            {{ $localize(item) }}
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="type in types"
                      :key="type"
          >
            <v-row class="justify-start mx-0">
              <v-col cols="12" md="8" class="pa-0 ma-0">
                <DictTreeView
                    :root="getRoot"
                    @dialogAdd="openDialogAdd"
                    @menuClick="menuClick"
                />
              </v-col>
              <v-col cols="12" md="4" class="pa-0 ma-0"></v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
        <div class="bottom-btn mt-2">
          <v-btn text
                 outlined
                 style="width: 100%"
                 @click="openDialogAdd(item)"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <!--        </v-col>-->
    <!--        <v-col cols="4" class="overflow-y-auto fill-parent-height">-->
    <!--          <v-card>-->
    <!--            <v-card-title>-->
    <!--              <br>dictionaries schema-->
    <!--            </v-card-title>-->
    <!--          </v-card>-->
    <!--        </v-col>-->
    <!--      </v-row>-->
    <v-dialog v-model="menuItem.dialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{ dialogProp.title }}</v-card-title>
        <v-card-text v-if="menuItem.type !== 'remove' && menuItem.type !== 'restore'" class="pa-2">
          <v-text-field
              v-model="item.title"
              :rules="[rules.required]"
              :readonly="menuItem.type === 'details'"
              outlined
              dense
              label="Значение"
              class="mb-0"
              hide-details
              @keydown.enter="ok"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">{{$localize('cancel')}}</v-btn>
          <v-btn
              :outlined="!isFormFilled"
              color="success"
              @click="ok">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DictTreeView from "./components/DictTreeView";

export default {
  name: "Dictionaries",
  components: {DictTreeView},
  data() {
    return {
      active: [],
      root: {
        uid: 0,
        title: "root",
        items: [],
      },
      menuItem: {
        dialog: false,
        type: undefined,
      },
      tab: undefined,
      types: [],
      allowedTypes: ['ACTIVITY', 'ASSETS', 'PASSIVE', 'PROFIT'],
      //fix on the backend
      dictItemsOrder: [
        'Выручка',
        'Себестоимость продаж',
        'Валовая прибыль (убыток)',
        'Административные расходы',
        'в т.ч. амортизация',
        'Расходы по реализации',
        'Прибыль (убыток) от продаж',
        'Проценты к получению',
        'Проценты к уплате',
        'Прочие доходы',
        'Прочие расходы',
        'Прибыль (убыток) до налогообложения',
        'Текущий налог на прибыль',
        'Прочее',
        'Чистая прибыль (убыток)'
      ],
      item: {
        parentUid: undefined,
        type: undefined,
        title: undefined,
        uid: undefined,
      },
      rules: {
        required: value => !!value || this.$localize('mandatory_field'),
      }
    }
  },

  computed: {
    getRoot(){
      let tabRoot = this.$lodash.clone(this.root)
      tabRoot.items = this.root.items.filter(el => {return el.type === this.types[this.tab]})
      this.clearRemovedChild(tabRoot.items)
      return tabRoot
    },

    dialogProp(){
      switch (this.menuItem.type){
        case 'add' : return {title: this.$localize('add_dictionary')}
        case 'edit' : return { title: this.$localize('edit_dictionary')}
        case 'remove' : return { title: this.$localize('are_you_sure_you_want_to_delete')}
        case 'restore' : return { title: this.$localize('are_you_sure_you_want_to_restore')}
        case 'details' : return { title: this.$localize('detailed_information')}
        default : return {title: ''}
      }
    },

    isFormFilled() {
      return this.item.title || this.menuItem.type === 'remove';
    },
  },

  mounted() {
    this.initTypes()
    this.init()
  },

  methods:{
    clearRemovedChild(arr){
      arr.forEach(el => {
        if(el.items && el.deleted) el.items= []
        else if(el.items) this.clearRemovedChild(el.items)
      })
    },

    async init(){
      let res = await this.$fetch.get('api/dictionary/get-list')
      this.root.items = res;
      console.log('this.root.items in init>>')
      console.log(this.root.items)
      this.sortItems();
    },

    sortItems(){
      if(this.root.items && this.root.items.length > 0){
        //remove deleted by admin
        this.root.items = this.root.items.filter(i => !i.isAdmin || (i.isAdmin && !i.deleted) )
        this.root.items.forEach(i => {
          if(i.items && i.items.length) i.items = i.items.filter(i => !i.isAdmin || (i.isAdmin && !i.deleted))
        })
        this.root.items = this.root.items.filter(i => !i.items || (i.items && i.items.length))
        this.root.items = this.root.items.map( e => {
          e.sortBy = this.dictItemsOrder.indexOf(e.title) !== -1 ? this.dictItemsOrder.indexOf(e.title) : this.root.items.length;
          return e;
        });
        this.root.items.sort( (a,b) => a.sortBy - b.sortBy);
      }
    },

    async initTypes(){
      this.types = await this.$fetch.get('api/dictionary/get-types')
      this.types = this.types.filter(t => {return this.allowedTypes.indexOf(t) !== -1})
      this.types.sort((a,b) =>  this.allowedTypes.indexOf(a) - this.allowedTypes.indexOf(b));
      // let first = "ACTIVITY";
      // types.sort(function(x,y){ return x == first ? -1 : y == first ? 1 : 0; });
    },

    ok(){
      if(!this.isFormFilled) return
      switch (this.menuItem.type){
        case 'add' : this.addItem()
          break
        case 'edit' : this.editItem()
          break
        case 'remove' : this.removeItem()
          break
        case 'details' : this.closeDialog()
          break
        case 'restore' : this.restoreDialog()
          break
      }
    },

    menuClick(menu, item){
      switch (menu.action){
        case 'add':  return this.openDialogAdd(item)
        case 'edit': return this.openDialogEdit(item)
        case 'details': return this.openDialogDetails(item)
        case 'remove': return this.openDialogRemove(item)
        case 'restore': return this.openDialogRestore(item)
      }
    },

    openDialogAdd(item){
      const cloned = this.$lodash.clone(item);
      this.item.parentUid = cloned.uid
      this.item.type = cloned.type
      this.menuItem.type = 'add'
      this.menuItem.dialog = true
    },

    async addItem(){
      this.item.type = this.types[this.tab]
      this.$appUtils.setOverlay(true)
      await this.$fetch.post('api/dictionary/save', this.item)
      this.$appUtils.setOverlay(false)
      await this.init()
      this.closeDialog()
    },

    openDialogEdit(item){
      this.item = this.$lodash.clone(item);
      this.menuItem.type = 'edit'
      this.menuItem.dialog = true
    },

    async editItem(){
      this.$appUtils.setOverlay(true)
      await this.$fetch.post('api/dictionary/save', this.item)
      this.$appUtils.setOverlay(false)
      await this.init()
      this.closeDialog()
    },

    openDialogRemove(item){
      this.item.uid = item.uid
      this.menuItem.type = 'remove'
      this.menuItem.dialog = true
    },

    async removeItem(){
      this.$appUtils.setOverlay(true)
      await this.$fetch.post('api/dictionary/delete?uid=' + this.item.uid)
      this.$appUtils.setOverlay(false)
      await this.init()
      this.closeDialog()
    },

    openDialogRestore(item){
      this.item.uid = item.uid
      this.menuItem.type = 'restore'
      this.menuItem.dialog = true
    },

    async restoreDialog(){
      this.$appUtils.setOverlay(true)
      await this.$fetch.post('api/dictionary/restore?uid=' + this.item.uid)
      this.$appUtils.setOverlay(false)
      await this.init()
      this.closeDialog()
    },

    openDialogDetails(item){
      this.item = this.$lodash.clone(item);
      this.menuItem.type = 'details'
      this.menuItem.dialog = true
    },

    async closeDialog(){
      this.menuItem.dialog = false
      await this.timeout(100)
      this.menuItem.type = undefined
      this.clearNewItem()
    },

    clearNewItem(){
      this.item.parentUid = undefined
      this.item.type = undefined
      this.item.title = undefined
      this.item.uid = undefined
    },

    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  }
}
</script>
